<template>
  <div class="bac">
    <div>
      <el-card shadow="always" :v-loading="isLoading" style="width: 14rem;">
        <el-space>
          <el-input placeholder="类别名称" v-model="this.addTypeForm.name"/>
          <el-button @click="addType">添加类别</el-button>
        </el-space>
        <el-table
            :data="types"
            highlight-current-row
            max-height="200"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column label="类别名称" prop="name"></el-table-column>
          <el-table-column label="操作" >
            <template #default="scope">
              <el-button type="danger" @click="delType(scope.row.id)" v-show="scope.row.id!==0">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-card shadow="always" :v-loading="isLoading" style="width: 14rem;margin-top: 30px">
        <el-space>
          <el-select v-model="this.type" placeholder="类别" size="large" @change="changeType">
            <el-option
                v-for="item in types"
                :key="item.name"
                :label="item.name"
                :value="item.id"

            />
          </el-select>
          <el-button text @click="dialogVisible = true">添加活动</el-button>
        </el-space>

        <el-table
            :data="activities"
            highlight-current-row
            max-height="800"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column label="封面">
            <template #default="scope">
              <el-image :src="scope.row.pic"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="活动名称" prop="name"></el-table-column>
          <el-table-column label="创建时间" prop="createTime"></el-table-column>
          <el-table-column label="开始时间" prop="beginTime"></el-table-column>
          <el-table-column label="结束时间" prop="endTime"></el-table-column>
          <el-table-column label="点击量" prop="clicks"></el-table-column>
          <el-table-column label="剩余天数" prop="remainDays"></el-table-column>

            <el-table-column label="活动状态">
                <template #default="scope">
                {{scope.row.status === 0? '未结束':'已结束' }}
                </template>
            </el-table-column>
          <el-table-column label="操作" >
            <template #default="scope">
              <el-button type="danger" @click="delActivity(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-pagination
          background
          layout="prev, pager, next"
          :total="categoryForm.total"
          v-model:current-page="categoryForm.currentPage"
          v-model:page-size="categoryForm.size"
          style=";--el-pagination-hover-color: #ffffff;"/>
    </div>


    <el-dialog
        v-model="dialogVisible"
        title="活动管理"
        width="30%"
    >
      <el-form
          label-width="100px"
          :model="addForm"
          style="max-width: 460px"
      >
        <el-form-item label="上传封面" prop="title">
          <img :src="addForm.pic" alt="" v-if="addForm.pic !== ''" style="width: 150px">
          <el-upload ref="upload"
                     action="#"
                     accept="image/png,image/gif,image/jpg,image/jpeg"
                     list-type="picture-card"
                     :auto-upload="false"
                     :on-change="imgChange"
                     v-if="addForm.pic === ''">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="活名称">
          <el-input v-model="addForm.name" />
        </el-form-item>
        <el-form-item label="跳转链接">
          <el-input v-model="addForm.url" />
        </el-form-item>
        <el-form-item label=活动时间>
          <span>开始时间：</span>
          <el-date-picker size="small" v-model="this.addForm.beginTime" type="date" format="YYYY-MM-DD"
                          value-format="YYYY-MM-DD" placeholder="开始时间"
                          style="margin-right: 45px"/>
          <span>结束时间：</span>
          <el-date-picker size="small" v-model="this.addForm.endTime" type="date" format="YYYY-MM-DD"
                          value-format="YYYY-MM-DD" placeholder="结束时间"/>
        </el-form-item>
        <el-form-item label=类别>
          <el-select v-model="this.addForm.categoryId" placeholder="类别" size="large">
            <el-option
                v-for="item in addTypeAc"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addActivity"
        >确认</el-button
        >
      </span>
      </template>
    </el-dialog>
  </div>

</template>

<script>
import {ElMessage, ElMessageBox, ElNotification} from "element-plus";
let formData = new FormData();
export default {
  name: "ActivityManage",
  data(){
    return{
      addTypeForm:{
        name:'',
      },
      dialogVisible:false,
      isLoading:true,
      info:[],
      activities:[],
      types:[],
      type:0,
      categoryForm:{
        currentPage:1,
        size:8,
        category:'',
        total:0,
      },
      deleteForm:{
        id:'',
      },
      addForm:{
        name: "",
        url: "",
        pic:'',
        beginTime: "",
        endTime: "",
        categoryId:'',
      },
      addTypeAc:[]
    }
  },
  mounted() {
    this.getAllType();
    this.changeType()
  },
  methods:{
    getAllType(){
      this.isLoading = true;
      this.$http({
        url:'/activity/category/getAll',
        method:'get'
      }).then(({data}) => {
        this.types = [];
        this.types.push({
          id:0,
          name:'全部类别'
        })
        for(let i=0;i<data.data.length;i++){
          this.types.push(data.data[i]);
        }
        this.addTypeAc = data.data;
        this.isLoading = false;
      })
    },
    changeType(){
      this.isLoading = true;
      this.categoryForm.category = this.type;
      this.$http({
        method:'get',
        url:'/activity/searchByCategory?currentPage=' + this.categoryForm.currentPage + '&&size=8&&categoryId=' + this.type,
      }).then(({data})=>{
        console.log(data);

        this.activities = data.data.data;

        this.categoryForm.total = data.data.total;
        this.isLoading = false;
      })
    },
    imgChange (files, fileList) {
      if (!(fileList[0].raw.type === 'image/png' || fileList[0].raw.type === 'image/gif' || fileList[0].raw.type === 'image/jpg' || fileList[0].raw.type === 'image/jpeg')) {
        this.$notify.warning({
          title: '警告',
          message: '请上传格式为image/png, image/gif, image/jpg, image/jpeg的图片'
        })
      }
      let size = fileList[0].raw.size / 1024 / 1024 / 2
      if (size > 2) {
        this.$notify.warning({
          title: '警告',
          message: '图片大小必须小于2M'
        })
        return;
      }
      formData.append('pic',fileList[0].raw)
      this.uploadPic();
    },
    addActivity(){
      console.log(this.addForm);
        this.$http({
          method:'post',
          url:'/activity/add',
          data:this.addForm
        }).then(({data})=>{
          if (data.code === 200){
            ElNotification({
              title: '上传成功',
              message: '',
              type: 'success',
            })
            this.dialogVisible = false;
            this.changeType();
          }
        })
    },
    delActivity(id){
      this.deleteForm.id = id;
      this.$http({
        method:'delete',
        url:'/activity/delete',
        params:{
          id:id
        }
      }).then(({data})=>{
        if (data.code === 200){
          ElNotification({
            title: '删除成功',
            message: '',
            type: 'success',
          })
          this.changeType();
        }
      })
    },
    uploadPic(){
      this.$http({
        method:'post',
        url:'/activity/upload',
        data: formData,
          headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(({data})=>{
        if(data.code === 200){
          this.addForm.pic = data.data;
        }
      })
    },
    addType(){
      console.log(this.addTypeForm)
      this.$http({
        method:'post',
        url:'/activity/category/add',
        params:{name:this.addTypeForm.name}
      }).then(({data})=>{
          if (data.code ===200){
            this.getAllType();
          }
      })
    },
    delType(id){
      ElMessageBox.confirm(
          '删除类别会删除该类别下的所有活动且不可恢复',
          '警告',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            ElMessageBox.confirm(
                '二次确认：删除类别会删除该类别下的所有活动且不可恢复',
                '警告',
                {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                }
            )
                .then(() => {
                  this.$http({
                    method:'delete',
                    url:'/activity/category/delete',
                    params:{
                      id:id
                    }
                  }).then(({data})=>{
                    if (data.code === 200){
                      ElMessage({
                        type: 'success',
                        message: '删除成功',
                      })
                      this.getAllType();
                      this.type=0;
                      this.changeType();
                    }
                  })

                })
                .catch(() => {
                  ElMessage({
                    type: 'info',
                    message: '删除已取消',
                  })
                })
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '删除已取消',
            })
          })
    },
  }
}
</script>

<style scoped>
.bac{
  height: 10rem;
}
</style>
